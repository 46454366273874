
import Firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBYj8ewGiHdIyYImZCxA-04NVuvHUNGhE0",
  authDomain: "kickstartx-fe64a.firebaseapp.com",
  projectId: "kickstartx-fe64a",
  storageBucket: "kickstartx-fe64a.appspot.com",
  messagingSenderId: "909023278781",
  appId: "1:909023278781:web:0255b399ecca1d434729d4",
  measurementId: "G-JNDW5NSBQK"
};

const firebase = Firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export { firebase, db };