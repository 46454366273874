import React from "react";
import { Container, Row, Col } from "reactstrap";
import artsandcrafts from "../../assests/images/arts _ crafts.png";
import IT  from "../../assests/images/IT Tools _ trainings.png";
import Professional from "../../assests/images/professional courses.png";
import "./courses.css";
import AllCources from "../Allcources/AllCources"
import CourseCard from "./CourseCard";
import { Route, Link, Routes} from "react-router-dom";
const coursesData = [
  {
    id: "01",
    title: "Art & craft",
     lesson: 12,
    students: 478,
    rating: 4,
    imgUrl: artsandcrafts,
  },
  {
    id: "02",
    title: "IT tools & traingings",
     lesson: 12,
    students: 179,
    rating: 4.7,
    imgUrl: IT,
  },
  {
    id: "03",
    title: " Professional courses",
     lesson: 12,
    students: 250,
    rating: 4.3,
    imgUrl: Professional,
  },  
];

const Courses = () => {
  return (
   
    <section id="Cources">
      <Container>
        <Row>
          <Col lg="12" className="mb-5">
            <div className="course__top d-flex justify-content-between align-items-center">
              <div className="course__top__left w-50">
                <h2>Our masterclasses on board</h2>
                
              </div>
              
              <div className="w-50 text-end">
               <Link to="/Allcources">  <button id="butn" className="btn">See All</button></Link>
              </div>
              <Routes>
                <Route path="/Allcources" element={<AllCources />}/>
              </Routes>
              
            </div>
          </Col>
          {coursesData.map((item) => (
            <Col lg="4" md="6" sm="6">
              <CourseCard key={item.id} item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
    
  );
};

export default Courses;
