import React  from "react";
import { Container, Row, Col } from "reactstrap";
import heart from '../../assests/images/icons8-heart-90.png'
import chooseImg from "../../assests/images/videoimg.png";
import "./choose-us.css";

const ChooseUs = () => {
  return (
    <section class="sectioncontainer">
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="choose__content">
            <div className="choose_head">
              <h2 id="love">Why our members love us<img src={heart}  alt="" width="90" height="90" /></h2>
              
              </div>
              
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="choose__img">
                <img src={chooseImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ChooseUs;
