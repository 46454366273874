import React, { Fragment } from "react";
import Header from "../components/Header/Header";
import HeroSection from "../components/Hero-Section/HeroSection";
import Courses from "../components/Courses-section/Courses";
import ChooseUs from "../components/Choose-us/ChooseUs";
import Features from "../components/Feature-section/Features";
import UniqueLearning from "../components/UniqueLearning/UniqueLearning";
import Help from "../components/Help/Help";
import Newsletter from "../components/Newsletter/Newsletter";
import Footer from "../components/Footer/Footer";
import Form from "../components/Form/Form";
const Home = () => {
  return (
    <Fragment>
      <Header />
      <HeroSection />
      <UniqueLearning />
      <Newsletter />
      <Courses />
      <ChooseUs />
      <Features />
      <Help />
      
      <Footer />
    </Fragment>
  );
};

export default Home;
