import Home from "./pages/Home";
import AllCources from "./components/Allcources/AllCources.jsx"
import Form from "./components/Form/Form.jsx"
import { Route, Routes} from "react-router-dom";
function App() {
  return(
  <Routes>
  <Route path="/" element={<Home />} /> 
  <Route path="/Allcources" element={<AllCources />} /> 
  <Route path="/Form" element={<Form />} /> 
  </Routes>
  );
}

export default App;
