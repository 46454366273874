import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import heroImg from "../../assests/images/hero-image.jpeg";
import "./hero-section.css";

const HeroSection = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="hero__content">
              <h1 className="mb-4 hero__title" >
              <div>Go beyond learning,</div>
              <div className="wrapper">
              <div className="statictext">with </div>
              
              <ul className="dynamictext">
              
                 <li><span>Expertise</span></li>
                 <li><span>Experts</span></li>
                 <li><span>Experience</span></li> 
               </ul></div>
              
              
                
              </h1>
              <p className="mb-5" >
              <strong>We offer you a sense of growth and help you achieve more through our live <span className="oneonone">one-to-one</span> and one-to-six training sessions</strong>
                <strong>Together with industry professionals, we provide you with a sense of growth to help you achieve more and advance in your career.</strong>
                
              </p>
            </div>
            <div className="search">
              
              <Link to="/Form"><button id="butn" className="btn"><a href="#Form" id="but" >Book a Demo</a></button></Link>
            </div>
          </Col>

          <Col lg="6" md="6">
            <img src={heroImg} alt="" className="w-100 hero__img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
