import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./features.css";

const FeatureData = [
  {
    title: "Sitha Ram",
    desc: "This experience helped me learn to continuously develop skills beyond the classroom. I would love to share and connect with more learnings with kickstartx.",
    icon: "ri-draft-line",
  },

  {
    title: "Akhila Reddy",
    desc: "This experience not only taught me the correct techniques of the real world, but also how to learn independently, how to stick with a problem, and find a way to solve it.",
    icon: "ri-discuss-line",
  },

  {
    title: "Anusha Rao",
    desc: "Thanks for your support, I received my certificate. Even though your course was challenging, you were always there to assist me. I enjoyed your class tremendously.",
    icon: "ri-contacts-book-line",
  },
  {
    title: "Rakesh Sirineni",
    desc: "I wrapped a real-life experience around the assignments and it was a tremendous learning experience! In all, I would rate the Online-learning course 9.5 out of 10.",
    icon: "ri-contacts-book-line",
  },
];

const Features = () => {
  return (
    <section className="choosesection">
      <Container>
        <Row>
          {FeatureData.map((item, index) => (
            <Col lg="3" md="6" key={index}>
            <div className="cardborder">
              <div id="card" className="single__feature text-center px-4">
                <p>{item.desc}</p>
                 <h6>{item.title}</h6>
              </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Features;
