import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./UniqueLearning.css";

const FeatureData = [
  {
    title: "Live classes",
    desc: "Classrooms are not the only place to learn. Learning can be done through Live classes, which bring life to learning, where learners can participate in real-time discussions and breakout exercises during the live session.",
    head:"LEARNING",
  },

  {
    title: "Social connect",
    desc: " Reach out to our mentors and like-minded people and build relationships. They are here to help you through all phases of your learning and train you for innovation.",
    head:"COMMUNITY"
  },

  {
    title: "Viability (POW)",
    desc: "At kickstartx, we acknowledge the concept of proof of work. Our flow experts work with students to help them solve real-world problems and provide them hands-on experience.",
    head:"CREED"
  },
 
];

const UniqueLearning = () => {
  return (
    <section className="chosesection">
      <Container>
        <Row>
        <h2 id="h2">Our programs are tailored to meet your learning needs...</h2>
          {FeatureData.map((item, index) => (
            <Col lg="4" md="6" key={index}>
            <div className="cardborder">
              <div id="card1" className="single__feature text-center px-4">
              <h6 id="head">{item.head}</h6>
              <h6 id="title">{item.title}</h6>
                <p id="desc">{item.desc}</p>
                 
              </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default UniqueLearning;
