import React from "react";
import { Container, Row, Col } from "reactstrap";
import artsandcrafts from "../../assests/images/arts _ crafts.png";
import IT  from "../../assests/images/IT Tools _ trainings.png";
import Professional from "../../assests/images/professional courses.png";
import Hobby  from "../../assests/images/Hobby trainings.png";
import Competitive from "../../assests/images/competative exam.png";
import Languages from "../../assests/images/languages.png";
import Academics from "../../assests/images/Academics.png";
import "./AllCources.css";

import CourseCard from "../Courses-section/CourseCard";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const coursesData = [
  {
    id: "01",
    title: "Art & craft",
     lesson: 12,
    students: 478,
    rating: 4,
    imgUrl: artsandcrafts,
  },
  {
    id: "02",
    title: "IT tools & traingings",
     lesson: 12,
    students: 179,
    rating: 4.7,
    imgUrl: IT,
  },
  {
    id: "03",
    title: " Professional courses",
     lesson: 12,
    students: 250,
    rating: 4.3,
    imgUrl: Professional,
  },

  {
    id: "04",
    title: "Hobby trainings",
    lesson: 12,
    students: 274,
    rating: 4.5,
    imgUrl: Hobby,
  },
  {
    id: "05",
    title: " Competitive exams",
     lesson: 12,
    students: 325,
    rating: 4.3,
    imgUrl: Competitive,
  },
    {
    id: "06",
    title: "Languages ",
    lesson: 12,
    students: 275,
    rating: 4.3,
    imgUrl:Languages,
  },
   {
    id: "07",
    title: " Academics",
     lesson: 12,
    students: 509,
    rating: 4.2,
    imgUrl: Academics,
  },
  

  

  
];

const AllCourses = () => {
  return (
    
    <Container>
    <Header />
        <Row>
          <Col lg="12" className="mb-5">
          </Col>
          {coursesData.map((item) => (
            <Col lg="4" md="6" sm="6">
              <CourseCard key={item.id} item={item} />
            </Col>
          ))}
        </Row>
        <Footer />
      </Container>
      
      
  );
};

export default AllCourses;