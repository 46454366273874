import React from "react";
import "./newsletter.css";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const Newsletter = () => {
  return (
    <section>
      <Container className="newsletter">
        <Row>
          <Col lg="12" className="text-center">
            <h2 className="mb-4">Celebrating 5,000+ learning hours across amazing courses</h2>
            <div className="subscrib">
              
             <Link to="/AllCources"> <button id="btn" className="btn"> <a href="#Cources" id="btncourse">Browse Courses</a></button></Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Newsletter;
