import React from "react";
import { db } from "../../Firebase";
import "./Form.css"
import { Container, Row, Col } from "reactstrap";
import Header from "../Header/Header";
const Form = () => {
 const saveAnswer = (event) => {
    event.preventDefault();

    const elementsArray = [...event.target.elements];

    const formData = elementsArray.reduce((accumulator, currentValue) => {
      if (currentValue.id) {
        accumulator[currentValue.id] = currentValue.value;
      }

      return accumulator;
      
    }, {});

    db.collection("Leads").add(formData);
    document.Form.reset();
    alert("Response submitted our team will contact you shortly");
    
  };
  return (
   
      <Container >
      <Header />
        <Row>
          <Col lg="12"  className="text-center">
            <h2 className="mb-4" id="formname">Get in touch</h2>
            <form onSubmit={saveAnswer} name="Form" id="form">
            <div className="field">
            <h6 className="text">Name:</h6>
        <input className="input" type="text" id="Name" placeholder="Elon Musk" required></input>
        </div>
        <br/>
        <div className="field">
       <h6 id="mobilenum" className="text">Mobile</h6>
       <input className="input" type="tel"  id="code" placeholder="+91 " minLength="2" required></input>
        <input className="input" type="tel"  id="Mobile" placeholder="9999999999" minLength="10" required></input>
            </div>
        <br/>
        <div className="field">
        <h6 className="text">Email</h6>
         <input className="input" type="text" id="Email" placeholder="info@kickstartx.com" required></input>
             </div>
         <br/>
         <div className="field">
         <h6 className="text">Subject</h6>
        <input className="input" type="text" id="Subject" placeholder="Python" required></input>
            </div>
        <br/>
        <div className="field">
        <h6 className="text">Description</h6>
        <input className="input" type="text" id="Description" placeholder="Python begginer for next month"></input><br/>
        </div>
        
        <button  className="button">Request a call</button>
      </form>
          </Col>
        </Row>
      </Container>
   
  );
};

export default Form;
