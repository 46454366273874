import React from "react";
import { Container, Row, Col } from "reactstrap";
import heroImg from "../../assests/images/support.svg";
import "./Help.css";

const Help = () => {
  return (
    <section className="sectioncantainer">
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="hero__content">
              <h1 className="mb-4 hero__title" >
               <strong> We would love to connect with you, let’s connect.</strong>
              </h1>
            </div>
            <div className="search">
              
              <button className="btn"><a href="http://calendly.com/kickstartx" id="link">Schedule a call</a></button>
            </div>
          </Col>

          <Col lg="6" md="6">
            <img src={heroImg} alt="" className="w-100 hero__img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Help;
