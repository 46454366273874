import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import logo from './../../assests/images/Logo.png';
import "./header.css";

const navLinks = [
  {
  
    display: "Become a Tutor",
    url: "https://forms.gle/UGm3Uqxy74Pn22C57",
    class_name: ""
  },
  
{
  
  display: "Login",
  url: "#",
  class_name: "loginButton login",
  
}

];

const Header = () => {
  const menuRef = useRef();

  const menuToggle = () => menuRef.current.classList.toggle("active__menu");

  return (
    <header className="header">
      <Container>
        <div className="navigation d-flex align-items-right justify-content-between">
          <div className="logo">
            
             <Link to="/"> <img src={logo}  alt="kickstartx" height="100" width="200" /></Link>
            
          </div>
      
          <div className="nav d-flex align-items-center">
            <div className="nav__menu" ref={menuRef} onClick={menuToggle} >
              <ul className="nav__list">
                {navLinks.map((item, index) => (
                  <li key={index} className="nav__item" >
                    <a href={item.url} id="navbar" className={item.class_name}> {item.display} </a>
                  </li>
                ))}
              </ul>
            </div>

            
          </div>

          

          <div className="mobile__menu">
            <span>
              <i class="ri-menu-line" onClick={menuToggle}></i>
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
