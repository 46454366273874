import React from "react";
import { Link } from "react-router-dom";

import "./Coursecard.css";
const CourseCard = (props) => {
  const { imgUrl, title, students, rating, description } = props.item;

  return (
    <div className="single__course__item">
      <div className="course__img">
        <img src={imgUrl} alt="" className="w-100" />
      </div>

      <div className="course__details">
        <h6 className="course__title mb-4">{title}</h6>
       <p class="lesson d-flex align-items-center gap-1">{description}</p> 
        <div className=" d-flex justify-content-between align-items-center">
          

          <p className="students d-flex align-items-center gap-1">
            <i class="ri-user-line"></i> {students}
          </p>
        </div>

        <div className=" d-flex justify-content-between align-items-center">
          <p className="rating d-flex align-items-center gap-1">
            <i class="ri-star-fill"></i> {rating}
          </p>

          <p className="enroll d-flex align-items-center gap-1">
            <Link to="/Form"><a href="#Form" id="navbutton"> Enroll Now</a></Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
