import React from "react";
import { Container, Row, Col } from "reactstrap";
import logo from './../../assests/images/Logo.png'
import "./footer.css";



const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="8" md="8" className="mb-4">
            <h2 className=" d-flex align-items-center gap-1">
               <img src={logo}  alt="kickstartx" height="100" width="200" />
            </h2>

            <div className="follows">
            <p>Together with industry professionals, we provide you with a sense of growth to help you achieve more and advance in your career.</p>
             
            </div>
          </Col>

          

          <Col lg="3" md="6">
           <div className="follows">
              <p className="mb-0">Follow us on social media</p>
              <span>
                <a href="https://www.facebook.com/kickstartx/">
                  <i class="ri-facebook-line"></i>
                </a>
              </span>

              <span>
                <a href="https://www.instagram.com/thekickstartx/">
                  <i class="ri-instagram-line"></i>
                </a>
              </span>

              <span>
                <a href="https://www.linkedin.com/company/kickstartx">
                  <i class="ri-linkedin-line"></i>
                </a>
              </span>

              <span>
                <a href="https://twitter.com/thekickstartx">
                  <i class="ri-twitter-line"></i>
                </a>
              </span>
            </div><br/>
            <h6 className="fw-bold">Get in Touch</h6>
            <p> Phone: +91 9000233795 </p>
            <p>Email: info@kickstartx.com</p>
          </Col>
        </Row>
        <Row>
          <h6>© kickstartx Private Limited</h6>
        </Row>
      </Container>
      
    </footer>
  );
};

export default Footer;
